<template>
  <a href="https://discord.gg/kmB6cJwun9" class="card-frame">
    <div class="socials-box">
      <div class="stack" style="--stacks: 3">
        <span style="--index: 0"
          ><img
            src="../assets/discord.svg"
            class="discord-logo"
            style="opacity: 1"
        /></span>
        <span style="--index: 1"
          ><img
            src="../assets/discord.svg"
            class="discord-logo"
            style="opacity: 1"
        /></span>
        <span style="--index: 2"
          ><img
            src="../assets/discord.svg"
            class="discord-logo"
            style="opacity: 1"
        /></span>
      </div>
      <p>
        <!-- Get the latest tutorials by following TradeBot on YouTube. -->
        Experience our thriving TradeBot community by joining Discord.
      </p>
      <a class="socials-btn shake" href="https://discord.gg/kmB6cJwun9" target="_BLANK"
        >Join Now</a
      >
    </div>
  </a>
</template>

<style scoped>
.discord-logo {
  width: 140px;
}

.socials-box:hover {
  background-color: rgb(64, 78, 237, 0.4);
  --c: rgb(64, 78, 237);
}

.socials-box:hover > .socials-btn {
  --c: rgb(64, 78, 237);
}

p {
  width: 100%;
  margin-top: 0px;
}
</style>
