export default {
  async loadCandleData(file, title) {
    try {
      const { dates, data, volumes, closedOrders } = await import(`../data/${file}`);
      return {
        title,
        dates,
        data,
        volumes,
        closedOrders
      };
    } catch (error) {
      console.error("Error loading candle data:", error);
      return {
        title: title,
        data: [],
        volumes: [],
        closedOrders: []
      };
    }
  },

  async loadHistogramData(file, title) {
    try {
      const { xAxisData, seriesData } = await import(`../data/${file}`);
      return {
        title,
        xAxisData,
        seriesData        
      };
    } catch (error) {
      console.error("Error loading histogram data:", error);
      return {
        title: title,
        xAxisData: [],
        seriesData: []
      };
    }
  },
};
