<template>
    <main>
        <img id="bot" src="../assets/construction.png">
        <div class="links">
            <a href="https://tradebot.cash/"><p>Site</p></a>
            <a href="https://raydium.io/swap/?outputCurrency=GaRph5BcLZZ4sRh6EXKS6V7dicVanhcwa5iWEE6AbEYR"><p>How to Buy</p></a>
            <a href="https://dexscreener.com/solana/7mlmtmvrgg9o7m9qdeezeq4axuurtrsrtbpcwmnlw1y1"><p>Chart</p></a>
    
            <a href="https://t.me/+4rzeUr36t_1jNGYx"><p>Telegram</p></a>
            <a href="https://discord.gg/kmB6cJwun9"><p>Discord</p></a>
            <a href="https://twitter.com/tradebot_cash"><p>Twitter</p></a>
        </div>
    </main>
   
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Jersey+25&display=swap');

    main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    p {
        font-size: 28px;
        font-family: "Jersey 25", sans-serif;
        color: rgb(227, 227, 242);
        margin: 0px;
    }

    a {
        text-decoration: none;
        border: 5px ridge white;
        background-color: rgba(18, 18, 18, 0.70);
        border-radius: 5%;
        padding-top: 1%;
        padding-bottom: 1%;
        width: 250px;
    }

    p:hover {
        color: darkblue;
    }

    #bot {
        width: 45%;
        margin-bottom: 3%;
    }

    .links{
        display: flex;
        flex-wrap: wrap;
        gap: 100px;
        justify-content: center;
        width: 100%;
        margin-bottom: 4%;
    }

    @media only screen and (max-width: 600px) {
        .links {
            gap: 35px;
            width: 200px;
            padding: 20px;
        }

        #bot {
            margin-top: 15px;
            width: 50%;
        }
    }
</style>