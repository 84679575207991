<template>
    <v-chart class="chart" :option="option" autoresize />
</template>

<script setup>
import { use } from 'echarts/core'
import { BarChart, CandlestickChart } from 'echarts/charts'
import {
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    DataZoomComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import VChart, { THEME_KEY } from 'vue-echarts';
import { ref, provide } from 'vue';

use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    DataZoomComponent,
    BarChart,
    CandlestickChart,
    CanvasRenderer
]);

provide(THEME_KEY, 'dark');

const option = ref(
    {
        title: {
            text: "$BRETT Chart for 2024-03-02 to 2024-06-22",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "line",
            },
        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: false,
                },
            },
        },
        grid: [
            {
                left: "10%",
                right: "10%",
                bottom: 200,
            },
            {
                left: "10%",
                right: "10%",
                height: 80,
                bottom: 80,
            },
        ],
        xAxis: [
            {
                type: "category",
                data: [
                    '2024-03-02T00:00:00Z',
                    '2024-03-03T00:00:00Z',
                    '2024-03-04T00:00:00Z',
                    '2024-03-05T00:00:00Z',
                    '2024-03-06T00:00:00Z',
                    '2024-03-07T00:00:00Z',
                    '2024-03-08T00:00:00Z',
                    '2024-03-09T00:00:00Z',
                    '2024-03-10T00:00:00Z',
                    '2024-03-11T00:00:00Z',
                    '2024-03-12T00:00:00Z',
                    '2024-03-13T00:00:00Z',
                    '2024-03-14T00:00:00Z',
                    '2024-03-15T00:00:00Z',
                    '2024-03-16T00:00:00Z',
                    '2024-03-17T00:00:00Z',
                    '2024-03-18T00:00:00Z',
                    '2024-03-19T00:00:00Z',
                    '2024-03-20T00:00:00Z',
                    '2024-03-21T00:00:00Z',
                    '2024-03-22T00:00:00Z',
                    '2024-03-23T00:00:00Z',
                    '2024-03-24T00:00:00Z',
                    '2024-03-25T00:00:00Z',
                    '2024-03-26T00:00:00Z',
                    '2024-03-27T00:00:00Z',
                    '2024-03-28T00:00:00Z',
                    '2024-03-29T00:00:00Z',
                    '2024-03-30T00:00:00Z',
                    '2024-03-31T00:00:00Z',
                    '2024-04-01T00:00:00Z',
                    '2024-04-02T00:00:00Z',
                    '2024-04-03T00:00:00Z',
                    '2024-04-04T00:00:00Z',
                    '2024-04-05T00:00:00Z',
                    '2024-04-06T00:00:00Z',
                    '2024-04-07T00:00:00Z',
                    '2024-04-08T00:00:00Z',
                    '2024-04-09T00:00:00Z',
                    '2024-04-10T00:00:00Z',
                    '2024-04-11T00:00:00Z',
                    '2024-04-12T00:00:00Z',
                    '2024-04-13T00:00:00Z',
                    '2024-04-14T00:00:00Z',
                    '2024-04-15T00:00:00Z',
                    '2024-04-16T00:00:00Z',
                    '2024-04-17T00:00:00Z',
                    '2024-04-18T00:00:00Z',
                    '2024-04-19T00:00:00Z',
                    '2024-04-20T00:00:00Z',
                    '2024-04-21T00:00:00Z',
                    '2024-04-22T00:00:00Z',
                    '2024-04-23T00:00:00Z',
                    '2024-04-24T00:00:00Z',
                    '2024-04-25T00:00:00Z',
                    '2024-04-26T00:00:00Z',
                    '2024-04-27T00:00:00Z',
                    '2024-04-28T00:00:00Z',
                    '2024-04-29T00:00:00Z',
                    '2024-04-30T00:00:00Z',
                    '2024-05-01T00:00:00Z',
                    '2024-05-02T00:00:00Z',
                    '2024-05-03T00:00:00Z',
                    '2024-05-04T00:00:00Z',
                    '2024-05-05T00:00:00Z',
                    '2024-05-06T00:00:00Z',
                    '2024-05-07T00:00:00Z',
                    '2024-05-08T00:00:00Z',
                    '2024-05-09T00:00:00Z',
                    '2024-05-10T00:00:00Z',
                    '2024-05-11T00:00:00Z',
                    '2024-05-12T00:00:00Z',
                    '2024-05-13T00:00:00Z',
                    '2024-05-14T00:00:00Z',
                    '2024-05-15T00:00:00Z',
                    '2024-05-16T00:00:00Z',
                    '2024-05-17T00:00:00Z',
                    '2024-05-18T00:00:00Z',
                    '2024-05-19T00:00:00Z',
                    '2024-05-20T00:00:00Z',
                    '2024-05-21T00:00:00Z',
                    '2024-05-22T00:00:00Z',
                    '2024-05-23T00:00:00Z',
                    '2024-05-24T00:00:00Z',
                    '2024-05-25T00:00:00Z',
                    '2024-05-26T00:00:00Z',
                    '2024-05-27T00:00:00Z',
                    '2024-05-28T00:00:00Z',
                    '2024-05-29T00:00:00Z',
                    '2024-05-30T00:00:00Z',
                    '2024-05-31T00:00:00Z',
                    '2024-06-01T00:00:00Z',
                    '2024-06-02T00:00:00Z',
                    '2024-06-03T00:00:00Z',
                    '2024-06-04T00:00:00Z',
                    '2024-06-05T00:00:00Z',
                    '2024-06-06T00:00:00Z',
                    '2024-06-07T00:00:00Z',
                    '2024-06-08T00:00:00Z',
                    '2024-06-09T00:00:00Z',
                    '2024-06-10T00:00:00Z',
                    '2024-06-11T00:00:00Z',
                    '2024-06-12T00:00:00Z',
                    '2024-06-13T00:00:00Z',
                    '2024-06-14T00:00:00Z',
                    '2024-06-15T00:00:00Z',
                    '2024-06-16T00:00:00Z',
                    '2024-06-17T00:00:00Z',
                    '2024-06-18T00:00:00Z',
                    '2024-06-19T00:00:00Z',
                    '2024-06-20T00:00:00Z',
                    '2024-06-21T00:00:00Z',
                    '2024-06-22T00:00:00Z'],
                scale: true,
                boundaryGap: true,
                // inverse: true,
                axisLine: { onZero: false },
                splitLine: { show: false },
                splitNumber: 20,
                min: "dataMin",
                max: "dataMax",
            },
            {
                type: "category",
                data: [
                    '2024-03-02T00:00:00Z',
                    '2024-03-03T00:00:00Z',
                    '2024-03-04T00:00:00Z',
                    '2024-03-05T00:00:00Z',
                    '2024-03-06T00:00:00Z',
                    '2024-03-07T00:00:00Z',
                    '2024-03-08T00:00:00Z',
                    '2024-03-09T00:00:00Z',
                    '2024-03-10T00:00:00Z',
                    '2024-03-11T00:00:00Z',
                    '2024-03-12T00:00:00Z',
                    '2024-03-13T00:00:00Z',
                    '2024-03-14T00:00:00Z',
                    '2024-03-15T00:00:00Z',
                    '2024-03-16T00:00:00Z',
                    '2024-03-17T00:00:00Z',
                    '2024-03-18T00:00:00Z',
                    '2024-03-19T00:00:00Z',
                    '2024-03-20T00:00:00Z',
                    '2024-03-21T00:00:00Z',
                    '2024-03-22T00:00:00Z',
                    '2024-03-23T00:00:00Z',
                    '2024-03-24T00:00:00Z',
                    '2024-03-25T00:00:00Z',
                    '2024-03-26T00:00:00Z',
                    '2024-03-27T00:00:00Z',
                    '2024-03-28T00:00:00Z',
                    '2024-03-29T00:00:00Z',
                    '2024-03-30T00:00:00Z',
                    '2024-03-31T00:00:00Z',
                    '2024-04-01T00:00:00Z',
                    '2024-04-02T00:00:00Z',
                    '2024-04-03T00:00:00Z',
                    '2024-04-04T00:00:00Z',
                    '2024-04-05T00:00:00Z',
                    '2024-04-06T00:00:00Z',
                    '2024-04-07T00:00:00Z',
                    '2024-04-08T00:00:00Z',
                    '2024-04-09T00:00:00Z',
                    '2024-04-10T00:00:00Z',
                    '2024-04-11T00:00:00Z',
                    '2024-04-12T00:00:00Z',
                    '2024-04-13T00:00:00Z',
                    '2024-04-14T00:00:00Z',
                    '2024-04-15T00:00:00Z',
                    '2024-04-16T00:00:00Z',
                    '2024-04-17T00:00:00Z',
                    '2024-04-18T00:00:00Z',
                    '2024-04-19T00:00:00Z',
                    '2024-04-20T00:00:00Z',
                    '2024-04-21T00:00:00Z',
                    '2024-04-22T00:00:00Z',
                    '2024-04-23T00:00:00Z',
                    '2024-04-24T00:00:00Z',
                    '2024-04-25T00:00:00Z',
                    '2024-04-26T00:00:00Z',
                    '2024-04-27T00:00:00Z',
                    '2024-04-28T00:00:00Z',
                    '2024-04-29T00:00:00Z',
                    '2024-04-30T00:00:00Z',
                    '2024-05-01T00:00:00Z',
                    '2024-05-02T00:00:00Z',
                    '2024-05-03T00:00:00Z',
                    '2024-05-04T00:00:00Z',
                    '2024-05-05T00:00:00Z',
                    '2024-05-06T00:00:00Z',
                    '2024-05-07T00:00:00Z',
                    '2024-05-08T00:00:00Z',
                    '2024-05-09T00:00:00Z',
                    '2024-05-10T00:00:00Z',
                    '2024-05-11T00:00:00Z',
                    '2024-05-12T00:00:00Z',
                    '2024-05-13T00:00:00Z',
                    '2024-05-14T00:00:00Z',
                    '2024-05-15T00:00:00Z',
                    '2024-05-16T00:00:00Z',
                    '2024-05-17T00:00:00Z',
                    '2024-05-18T00:00:00Z',
                    '2024-05-19T00:00:00Z',
                    '2024-05-20T00:00:00Z',
                    '2024-05-21T00:00:00Z',
                    '2024-05-22T00:00:00Z',
                    '2024-05-23T00:00:00Z',
                    '2024-05-24T00:00:00Z',
                    '2024-05-25T00:00:00Z',
                    '2024-05-26T00:00:00Z',
                    '2024-05-27T00:00:00Z',
                    '2024-05-28T00:00:00Z',
                    '2024-05-29T00:00:00Z',
                    '2024-05-30T00:00:00Z',
                    '2024-05-31T00:00:00Z',
                    '2024-06-01T00:00:00Z',
                    '2024-06-02T00:00:00Z',
                    '2024-06-03T00:00:00Z',
                    '2024-06-04T00:00:00Z',
                    '2024-06-05T00:00:00Z',
                    '2024-06-06T00:00:00Z',
                    '2024-06-07T00:00:00Z',
                    '2024-06-08T00:00:00Z',
                    '2024-06-09T00:00:00Z',
                    '2024-06-10T00:00:00Z',
                    '2024-06-11T00:00:00Z',
                    '2024-06-12T00:00:00Z',
                    '2024-06-13T00:00:00Z',
                    '2024-06-14T00:00:00Z',
                    '2024-06-15T00:00:00Z',
                    '2024-06-16T00:00:00Z',
                    '2024-06-17T00:00:00Z',
                    '2024-06-18T00:00:00Z',
                    '2024-06-19T00:00:00Z',
                    '2024-06-20T00:00:00Z',
                    '2024-06-21T00:00:00Z',
                    '2024-06-22T00:00:00Z'],
                gridIndex: 1,
                scale: true,
                boundaryGap: false,
                axisLine: { onZero: false },
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: { show: false },
                splitNumber: 20,
                min: "dataMin",
                max: "dataMax",
            },
        ],
        yAxis: [
            {
                scale: true,
                splitArea: {
                    show: true,
                },
            },
            {
                scale: true,
                gridIndex: 1,
                splitNumber: 2,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: true },
            },
            {
                scale: true,
                gridIndex: 1,
                splitNumber: 2,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: true },
            },
        ],
        dataZoom: [
            {
                type: "inside",
                xAxisIndex: [0, 1],
                start: 0,
                end: 100,
            },
            {
                show: true,
                xAxisIndex: [0, 1],
                type: "slider",
                bottom: 10,
                start: 0,
                end: 100,
            },
        ],
        series: [
            {
                name: "Volume",
                type: "bar",
                xAxisIndex: 1,
                yAxisIndex: 1,
                itemStyle: {
                    color: "#7fbe9e",
                },
                emphasis: {
                    itemStyle: {
                        color: "#140",
                    },
                },
                data: [
                    612662027.220624,
                    52438428.913436,
                    3202006.997476,
                    14769645.562283,
                    20738395.522177,
                    13818410.906805,
                    5173052.826861,
                    16019383.402153,
                    3209031.407548,
                    4508967.744402,
                    218739.172895,
                    881242.066293,
                    73049.359455,
                    3173949.341398,
                    0.000000,
                    13490.341986,
                    23277.262880,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    27186.297368,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    7903458.641092,
                    1586249.461440,
                    43729.282914,
                    14772935.958054,
                    0.000000,
                    69621.324405,
                    69598.693625,
                    69598.693625,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    3166291.806786,
                    23055.323681,
                    0.000000,
                    122376.577197,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    366099.379587,
                    0.000000,
                    0.000000,
                    8021393.189677,
                    17041344.376178,
                    0.000000,
                    0.000000,
                    1765.052170,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    69098.788148,
                    0.000000,
                    0.000000,
                    0.000000,
                    1538317.893103,
                    0.000000,
                    0.000000,
                    0.000000,
                    0.000000,
                    371881.106450,
                    0.000000],
            },
            {
                type: "candlestick",
                data: [
                    [0.00000000, 0.00045907, 0.00000000, 0.00000765],
                    [0.00000765, 0.00001149, 0.00000738, 0.00000921],
                    [0.00000921, 0.00000921, 0.00000898, 0.00000898],
                    [0.00000898, 0.00000994, 0.00000838, 0.00000920],
                    [0.00000920, 0.00001106, 0.00000794, 0.00001106],
                    [0.00001106, 0.00001522, 0.00001058, 0.00001069],
                    [0.00001069, 0.00001114, 0.00001069, 0.00001109],
                    [0.00001109, 0.00001134, 0.00001015, 0.00001035],
                    [0.00001035, 0.00001049, 0.00001025, 0.00001025],
                    [0.00001025, 0.00001025, 0.00000920, 0.00000920],
                    [0.00000920, 0.00000935, 0.00000920, 0.00000935],
                    [0.00000935, 0.00000975, 0.00000935, 0.00000975],
                    [0.00000975, 0.00001022, 0.00000975, 0.00001022],
                    [0.00001022, 0.00001149, 0.00001022, 0.00001104],
                    [0.00001104, 0.00001104, 0.00001104, 0.00001104],
                    [0.00001104, 0.00001217, 0.00001104, 0.00001217],
                    [0.00001217, 0.00001258, 0.00001217, 0.00001258],
                    [0.00001258, 0.00001258, 0.00001258, 0.00001258],
                    [0.00001258, 0.00001258, 0.00001258, 0.00001258],
                    [0.00001258, 0.00001258, 0.00001258, 0.00001258],
                    [0.00001258, 0.00001258, 0.00001258, 0.00001258],
                    [0.00001258, 0.00001258, 0.00001065, 0.00001065],
                    [0.00001065, 0.00001065, 0.00001065, 0.00001065],
                    [0.00001065, 0.00001065, 0.00001065, 0.00001065],
                    [0.00001065, 0.00001065, 0.00001065, 0.00001065],
                    [0.00001065, 0.00001065, 0.00001065, 0.00001065],
                    [0.00001065, 0.00001210, 0.00001065, 0.00001187],
                    [0.00001187, 0.00001187, 0.00001152, 0.00001152],
                    [0.00001152, 0.00001213, 0.00001152, 0.00001213],
                    [0.00001213, 0.00001321, 0.00001213, 0.00001321],
                    [0.00001321, 0.00001321, 0.00001321, 0.00001321],
                    [0.00001321, 0.00001321, 0.00001126, 0.00001126],
                    [0.00001126, 0.00001126, 0.00001126, 0.00001126],
                    [0.00001126, 0.00001126, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00001120, 0.00001120],
                    [0.00001120, 0.00001120, 0.00000912, 0.00000912],
                    [0.00000912, 0.00000912, 0.00000903, 0.00000903],
                    [0.00000903, 0.00000903, 0.00000903, 0.00000903],
                    [0.00000903, 0.00000903, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00000887, 0.00000887, 0.00000887],
                    [0.00000887, 0.00001034, 0.00000887, 0.00001034],
                    [0.00001034, 0.00001034, 0.00001034, 0.00001034],
                    [0.00001034, 0.00001034, 0.00001034, 0.00001034],
                    [0.00001034, 0.00001134, 0.00001034, 0.00001134],
                    [0.00001134, 0.00001170, 0.00001052, 0.00001108],
                    [0.00001108, 0.00001108, 0.00001108, 0.00001108],
                    [0.00001108, 0.00001108, 0.00001108, 0.00001108],
                    [0.00001108, 0.00001108, 0.00001061, 0.00001061],
                    [0.00001061, 0.00001061, 0.00001061, 0.00001061],
                    [0.00001061, 0.00001061, 0.00001061, 0.00001061],
                    [0.00001061, 0.00001061, 0.00001061, 0.00001061],
                    [0.00001061, 0.00001061, 0.00001061, 0.00001061],
                    [0.00001061, 0.00001061, 0.00000980, 0.00000980],
                    [0.00000980, 0.00000980, 0.00000980, 0.00000980],
                    [0.00000980, 0.00000980, 0.00000980, 0.00000980],
                    [0.00000980, 0.00000980, 0.00000980, 0.00000980],
                    [0.00000980, 0.00000980, 0.00000881, 0.00000881],
                    [0.00000881, 0.00000881, 0.00000881, 0.00000881],
                    [0.00000881, 0.00000881, 0.00000881, 0.00000881],
                    [0.00000881, 0.00000881, 0.00000881, 0.00000881],
                    [0.00000881, 0.00000881, 0.00000881, 0.00000881],
                    [0.00000881, 0.00000881, 0.00000800, 0.00000800],
                    [0.00000800, 0.00000800, 0.00000800, 0.00000800]],
                itemStyle: {
                    color: "#14b143",
                    color0: "#ef232a",
                    borderColor: "#14b143",
                    borderColor0: "#ef232a",
                },
                emphasis: {
                    itemStyle: {
                        color: "black",
                        color0: "#444",
                        borderColor: "black",
                        borderColor0: "#444",
                    },
                },
                encode: {
                    x: 0,
                    y: [1, 4, 3, 2],
                },
            },
        ],
    }
);

</script>

<style scoped>
.chart {
    height: 70vh;
    width: 90%;
}
</style>