<template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup>
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, provide, onMounted } from "vue";
import dataService from "../services/DataService";

use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
]);

provide(THEME_KEY, "dark");

const option = ref({});

const loadChartData = async () => {
  option.value = {
    title: {
      text: "Distribution of Net Value for $BRETT Traders - 6/26/2024",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: [
        "$-2200 to $-2100",
        "$-1400 to $-1300",
        "$-1300 to $-1200",
        "$-1200 to $-1100",
        "$-1100 to $-1000",
        "$-1000 to $-900",
        "$-700 to $-600",
        "$-600 to $-500",
        "$-500 to $-400",
        "$-400 to $-300",
        "$-300 to $-200",
        "$-200 to $-100",
        "$-100 to $0",
        "$0 to $100",
        "$100 to $200",
        "$200 to $300",
        "$300 to $400",
        "$600 to $700",
        "$700 to $800",
        "$1200 to $1300",
        "$1700 to $1800",
        "$2300 to $2400",
        "$3000 to $3100",
      ],
      axisLabel: {
        rotate: 45,
        interval: 0,
      },
    },
    yAxis: {
      type: "value",
      name: "Number of Investors",
    },
    series: [
      {
        name: "Number of Investors",
        type: "bar",
        data: [
          { value: 1, itemStyle: { color: "red" } },
          { value: 1, itemStyle: { color: "red" } },
          { value: 2, itemStyle: { color: "red" } },
          { value: 1, itemStyle: { color: "red" } },
          { value: 1, itemStyle: { color: "red" } },
          { value: 2, itemStyle: { color: "red" } },
          { value: 6, itemStyle: { color: "red" } },
          { value: 8, itemStyle: { color: "red" } },
          { value: 8, itemStyle: { color: "red" } },
          { value: 16, itemStyle: { color: "red" } },
          { value: 26, itemStyle: { color: "red" } },
          { value: 80, itemStyle: { color: "red" } },
          { value: 1018, itemStyle: { color: "red" } },
          { value: 397, itemStyle: { color: "green" } },
          { value: 153, itemStyle: { color: "green" } },
          { value: 97, itemStyle: { color: "green" } },
          { value: 7, itemStyle: { color: "green" } },
          { value: 1, itemStyle: { color: "green" } },
          { value: 2, itemStyle: { color: "green" } },
          { value: 1, itemStyle: { color: "green" } },
          { value: 1, itemStyle: { color: "green" } },
          { value: 1, itemStyle: { color: "green" } },
          { value: 1, itemStyle: { color: "green" } },
        ],
      },
    ],
  };
};

onMounted(loadChartData);
</script>

<style scoped>
.chart {
  height: 50vh;
  width: 100%;
}
</style>
