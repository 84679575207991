<template>
    
        <section class="container">
      <p>
        <b>Disclaimer:</b> 
        It is important to note that <span class="tradebot-highlight">$TRADEBOT</span> is a community-owned memecoin,
        relying heavily on the active participation and support of its community
        members. Inherently, <span class="tradebot-highlight">$TRADEBOT</span> possesses no intrinsic value and should
        be regarded primarily as an entertainment token. Prospective investors
        are encouraged to approach it with the understanding that its value is
        largely driven by community engagement and speculative interest. 
      </p>
      <div class="stack" style="--stacks: 3">
        <span style="--index: 0"
          ><img
            class="tradebot-size"
            src="../assets/thumbs-up-bot.png"
            alt="blue robot with thumbs up"
        /></span>
        <span style="--index: 1"
          ><img
            class="tradebot-size"
            src="../assets/thumbs-up-bot.png"
            alt="blue robot with thumbs up"
        /></span>
        <span style="--index: 2"
          ><img
            class="tradebot-size"
            src="../assets/thumbs-up-bot.png"
            alt="blue robot with thumbs up"
        /></span>
      </div>
    </section>
   
</template>

<style scoped>
p {
  margin-top: 15px;
}

</style>