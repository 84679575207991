<template>
  <section class="view">
    <section class="container">
      <div id="container">
        <h1 class="glitch-text">Socials</h1>
        <h1 class="glitch-text">Socials</h1>
        <h1 class="glitch-text">Socials</h1>
      </div>

      <img class="phone-bot" src="../assets/phone-bot.png" alt="robot that is using a yellow phone"/>
      <section class="card-flex">
        <discord-component class="card"></discord-component>
        <twitter-component class="card"></twitter-component>
        <you-tube-component class="card"></you-tube-component>
      </section>
      <section class="card-flex">
        <telegram-component class="card"></telegram-component>
        <chart-component class="card"></chart-component>
      </section>
    </section>
  </section>
</template>

<script>
import DiscordComponent from '../components/DiscordComponent.vue';
import TwitterComponent from "../components/TwitterComponent.vue";
import YouTubeComponent from '../components/YouTubeComponent.vue';
import TelegramComponent from '../components/TelegramComponent.vue';
import ChartComponent from '../components/ChartComponent.vue';

export default {
  name: "SocialView",
  components: {
    DiscordComponent,
    TwitterComponent,
    YouTubeComponent,
    TelegramComponent,
    ChartComponent
  },
};
</script>

<style scoped>

#container {
  margin: 45px auto;
}

.glitch-text {
  letter-spacing: 8px;
}

.phone-bot {
  width: 150px;
}

.card-flex {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 400px) {
  .glitch-text {
    font-size: 23px;
    letter-spacing: 4px;
  }
}

@media only screen and (min-width: 768px) {
  .phone-bot {
    width: 200px;
  }
}

@media only screen and (min-width: 870px) {
  .card-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 3%;
    gap: 5%;
  }
}
</style>
