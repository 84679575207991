<template>
  <section class="view">
    <section class="container">
      <h1 class="padding">TradeBot Whitepaper</h1>

      <img
        src="../assets/diploma-bot.png"
        class="tradebot-size"
        alt="robot holding a diploma"
      />

      <h2>Introduction</h2>
      <p>
        TradeBot ($TRADEBOT) is a community-owned memecoin on the Solana
        blockchain, designed to protect holders from market dumps through its
        innovative self-healing technology. This whitepaper explores the current
        culture of memecoins, the application of the Greater Fool theory, and
        the broader ramifications for investors. We introduce TradeBot's
        self-healing technology, which aims to safeguard holders and strengthen
        the Solana community. By examining a case study of the $BRETT token, we
        will demonstrate how TradeBot stabilizes token prices and benefits
        holders, encouraging new investors to join the TradeBot and Solana
        communities.
      </p>

      <h2>Current Culture of Memecoins</h2>
      <p>
        Memecoins have become a distinctive segment within the cryptocurrency
        market, often viewed as gambling tools that can yield either substantial
        gains or significant losses. These coins are driven purely by the
        popularity of their associated memes or logos, with no inherent utility.
      </p>

      <p>
        The proliferation of memecoins is evident, as illustrated by the
        existence of many different $BRETT tokens. $BRETT, inspired by Brett, a
        friend of the popular meme character PEPE the Frog, is an example of how
        memecoins capitalize on well-known internet memes. A search for BRETT
        returned over 5000 results spanning across multiple blockchains. For
        example, there are 3871 $BRETT tokens on the BASE blockchain, 656 $BRETT
        tokens on the Solana blockchain, and 245 $BRETT tokens on the Ethereum
        blockchain. This ease of duplication contributes to a culture of
        mistrust and paranoia among investors as token creators can easily
        abandon a project in order to create another token.
      </p>

      <img
        class="figures"
        src="../assets/whitepaper/brett-search-results.png"
        alt="Memes search results"
      />
      <caption>
        Memes hold immense marketing power, leading to repeated themes. The
        search for 'BRETT' returned over 5000 distinct $BRETT tokens on
        DexTools.
      </caption>

      <p>
        Developer 'rug pulls,' where token creators suddenly sell off their
        holdings, often leave investors scrambling to recover their losses. The
        impact can be particularly devastating. For example, in the case of
        $BRETT, 63.8% of all traders were negatively impacted.
      </p>

      <p>
        Investors who didn't sell early were left 'holding their bags,' enduring
        significant losses in the value of their holdings. Such scenarios are
        all too common in the world of memecoins, leading to a general consensus
        that the best strategy is to get in and out quickly. Our upcoming case
        study reveals that the majority of $BRETT holders had an average hold
        time of less than a day, highlighting the speculative and fleeting
        nature of memecoin investments.
      </p>

      <h2>Application of the Greater Fool Theory</h2>
      <p>
        The Greater Fool theory, which suggests that an investment's success
        relies on finding a "greater fool" to purchase the asset at a higher
        price, is particularly relevant to memecoins. Memecoins utilize
        Liquidity Pools (LP) to facilitate seamless transactions. A Liquidity
        Pool can be viewed as a vending machine that allows a trader to swap one
        token for another. The Liquidity Pool uses a mathematical formula to
        determine the swap rate so that one coin is never fully depleted. When a
        person buys a memecoin from the Liquidity Pool, the price goes up.
        Conversely, when a person sells their memecoin to the Liquidity Pool,
        the price goes down.
      </p>

      <p>
        When a memecoin is sold, the LP is depleted of the token that provides
        intrinsic value (Solana, in our case). This necessitates that new buyers
        replenish the pool. These new buyers could be considered "the greater
        fools".
      </p>

      <p>
        This zero-sum game ensures that every winner corresponds to a loser. Our
        case study of the $BRETT token will demonstrate this dynamic, with
        charts showing the disparity between winners and losers among token
        holders.
      </p>

      <h2>Case Study: $BRETT Token</h2>
      <p>
        To better understand the dynamics and challenges of memecoins, we will
        look at a case study: the $BRETT token. This case study illustrates the
        initial surge in price followed by significant sell-offs and the impact
        on holders.
      </p>

      <h3>Brett On Sol (ticker: $BRETT)</h3>
      <ul>
        <li>
          <strong>Contract Address:</strong>
          DyhNsutzHbMojnVYyuPZkiQG1HtYoHvKNnAgNrXFeVRw
        </li>
        <li>
          <strong>DexTools Explorer:</strong>
          <a
            href="https://www.dextools.io/app/en/solana/pair-explorer/Gn3v2HpTUxH7Kc3HXbMTHTBuon6TLGn2LQK9Xr4QGp5g?t=1719608929062"
            target="_blank"
            >Link</a
          >
        </li>
        <li><strong>Total Supply: </strong>100,000,000</li>
        <li><strong>Total Unique Traders: </strong> 1,831</li>
        <li><strong>Total Transactions: </strong>5,809</li>
        <li><strong>Total Tokens Traded: </strong>1,424,081,552 $BRETT</li>
        <li>
          <strong>Trading Period: </strong>June 7, 2024, 19:07:48 to June 26,
          2024, 08:03:24
        </li>
      </ul>

      <h3>Initial Surge and Sell-Off</h3>
      <p>
        The $BRETT token experienced an initial price surge driven by
        speculative buying. On June 7th, 2024 at 8:00 PM UTC, the price of
        $BRETT opened at $0.00023889. Within a couple of hours, it shot up to
        $0.00242581 in a flurry of activity. Sellers started taking profits and
        the price over a 2.5-week period fell to $0.00001919. These prices led
        to significant gains and significant losses.
      </p>

      <CandleChart
        filename="brett-on-sol.js"
        title="$BRETT Historical Chart"
      ></CandleChart>

      <p>
        Similar to the game of musical chairs, traders have to buy early and
        then strategically determine when to sell. This game of cat and mouse is
        highly risky but lucrative. Analyzing the wallets of all traders who
        participated, the following was found: 646 traders sold for a loss. 549
        traders sold for a profit and exited. A whopping 524 traders are
        "bag-holders" as they did not sell their coin, and currently have a
        portfolio value that is negative. 112 traders have holdings that still
        have a net positive value. Out of the 1831 unique traders, 1170 traders
        sold for a loss or are in the red. This is 63.8% of all holders. With
        these odds, an inexperienced investor has better chances with a coin
        flip.
      </p>

      <HistogramChart></HistogramChart>

      <p>
        Taking a look at the distribution of portfolio values, there is a small
        number of big winners, with the rest fighting for minimal gains. The big
        winners purchased early or had a zero cost-basis as insiders, further
        driving cynicism, paranoia, and mistrust within the community. Over half
        of all traders were negatively impacted. 1018 traders ended with a net
        value between -$100 and $0.
      </p>

      <PortfolioHistogram></PortfolioHistogram>

      <h2>Self-Healing Technology</h2>
      <p>
        TradeBot introduces self-healing technology through a buy-and-burn
        approach, leveraging consistent revenue streams to purchase and burn
        tokens. This process serves two primary purposes:
      </p>

      <ul>
        <li>
          <strong>Increase in Token Price:</strong> Regular token purchases
          increase demand, driving up the price.
        </li>
        <li>
          <strong>Community Assurance:</strong> Burning tokens signals to the
          community a commitment not to dump tokens back into the market,
          fostering trust and confidence.
        </li>
      </ul>

      <p>
        Our community is actively developing multiple revenue streams to support
        this self-healing mechanism, including a software development company, a
        trading engine, and various community events such as car shows and comic
        conventions. The trading engine, which is already operational, trades
        against major cryptocurrencies and generates monthly returns between 1%
        and 8%. These profits are used to buy and burn $TRADEBOT tokens,
        ensuring a consistent revenue stream to support the token's value.
      </p>

      <h2>Simulated Liquidity Pool with Self-Healing Technology</h2>
      <p>
        We simulated the $BRETT token transactions under a scenario where a
        consistent daily revenue stream is used to buy and burn tokens. This
        simulation demonstrates the positive impact of self-healing technology
        on token price stability:
      </p>

      <h3>$0 Buy and Burn using Simulated Liquidity Pool</h3>
      <p>
        To ensure the highest degree of accuracy, real-world transactions were
        processed against our simulated Liquidity Pool until our simulated
        transactions matched the real-world transactions. In this case, we
        followed K = x * y logic as provided by UniSwap documentation and we
        used a fee of 0.25%. Using this configuration we were able to replicate
        the real-world transactions of $BRETT. Compare this chart with the chart
        above. They match but were generated from two different sources.
      </p>

      <ul>
        <li><strong>Total $BRETT Burned: </strong>0</li>
        <li><strong>End Date: </strong>2024-06-26</li>
        <li><strong>Closing Price: </strong>$0.00001919</li>
        <li><strong>1,000,000 $BRETT: </strong>$19.19</li>
      </ul>
      <CandleChart
        filename="brett-on-sol-0.js"
        title="$BRETT with $0 daily heal rate"
      ></CandleChart>

      <h3>$10 Daily Buy-and-Burn using Simulated Liquidity Pool</h3>
      <p>
        The buys and burns start on 2024-06-26 at 10 AM. The chart shows a
        gradual and steady price increase over five months.
      </p>

      <ul>
        <li><strong>Total $BRETT Burned: </strong>36,830,108</li>
        <li><strong>End Date: </strong>2024-12-06</li>
        <li><strong>Closing Price: </strong>$0.00010262</li>
        <li><strong>1,000,000 $BRETT: </strong>$102.62</li>
      </ul>
      <CandleChart
        filename="brett-on-sol-10.js"
        title="$BRETT with $10 daily heal rate"
      ></CandleChart>

      <h3>$100 Daily Buy-and-Burn using Simulated Liquidity Pool</h3>
      <p>
        A more pronounced price increase is observed, mitigating sell-off
        impacts. By October 26th, 2024, the ATH price of $0.00242581 is
        breached, ensuring all holders are in the green. The number of days
        between the previous high on June 7th, 2024, and October 26th, 2024 is
        141 days.
      </p>

      <ul>
        <li><strong>Total $BRETT Burned: </strong>60,314,806</li>
        <li><strong>End Date: </strong>2024-12-06</li>
        <li><strong>Closing Price: </strong>$0.00380951</li>
        <li><strong>1,000,000 $BRETT: </strong>$3,809.51</li>
      </ul>
      <CandleChart
        filename="brett-on-sol-100.js"
        title="$BRETT with $100 daily heal rate"
      ></CandleChart>

      <h3>$1000 Daily Buy-and-Burn using Simulated Liquidity Pool</h3>
      <p>
        The goal for all of our cumulative efforts. A significant upward price
        trend over six months highlights the effectiveness of substantial daily
        investments.
      </p>

      <ul>
        <li><strong>Total $BRETT Burned: </strong>64,436,896</li>
        <li><strong>End Date: </strong>2024-12-06</li>
        <li><strong>Closing Price: </strong>$0.33231106</li>
        <li><strong>1,000,000 $BRETT: </strong>$332,311.06</li>
      </ul>
      <CandleChart
        filename="brett-on-sol-1000.js"
        title="$BRETT with $1000 daily heal rate"
      ></CandleChart>

      <h2>$TRADEBOT Case Study</h2>

      <p>
        In this case study, we will analyze the historical prices of TradeBot
        and present projections for different buy and burn rates using
        TradeBot's ($TRADEBOT) innovative Self-Healing Technology. These
        projections are based solely on the daily buy and burn mechanism,
        excluding the influence of new traders entering the market, existing
        traders exiting, or marketing efforts. This baseline scenario showcases
        the potential for price stabilization and growth driven purely by the
        Self-Healing Technology.
      </p>

      <h2>Analysis of TradeBot Data</h2>

      <p>
        The provided TradeBot transaction data includes various parameters such
        as token amounts, currency amounts, prices, and liquidity pool details.
        One interesting observation from the data is the steady increase in the
        price of $TRADEBOT in USD and its corresponding price in SOL. For
        example, the price per token starts at $0.000048 and gradually increases
        through subsequent transactions. This indicates a consistent demand for
        $TRADEBOT, contributing to its price appreciation. Additionally, the
        liquidity pool reflects growing participation, with significant token
        amounts being added and traded, which is a positive indicator of market
        activity and investor confidence.
      </p>

      <p>
        The average price of $TRADEBOT for the month of May 2024 was
        approximately $0.0008923, while for June 2024, it increased to about
        $0.0009939. These monthly averages show a slight upward trend in price,
        indicating a stable demand and gradual appreciation. However, as we will
        see, the price of $TRADEBOT outgrew the burn rate of its Self-Healing
        technology.
      </p>

      <CandleChart
        filename="tradebot.js"
        title="Historical Prices for $TRADEBOT up to 2024-06-30T02:38:07Z"
      ></CandleChart>

      <h3>Timeline of Events</h3>

      <p>
        <strong>May 22nd, 2024 - CoinMarketCap Listing: </strong>Notably, on May
        22, 2024, TradeBot was approved for listing on CoinMarketCap.
        CoinMarketCap boasts 30 million impressions per month. Upon our listing,
        this led to a flurry of activity from sniper bots, which increased the
        price to its all-time high (ATH). The subsequent sell-off was quick.
      </p>

      <p>
        <strong>May 24th, 2024 - First Major Seller: </strong>On May 24, 2024,
        we had our first major seller, one of the original buyers during the
        pump.fun incubation period.
      </p>

      <p>
        <strong>May 30th, 2024 - Airdrop Campaign: </strong>On May 30, 2024,
        TradeBot launched a significant airdrop campaign, distributing 5000
        $TRADEBOT tokens to each of over 800 wallets. This initiative was driven
        by the requirement to have at least 500 holders for VERIFIED listing on
        JupiterSwap. This event marked our first encounter with airdrop farmers
        who attempted to manipulate the system by claiming multiple allotments.
        Consequently, the selling pressure surged as the majority of airdrop
        recipients quickly sold their tokens.
      </p>

      <p>
        <strong>May 31st, 2024 - MEV Bot Incident:</strong> On May 31, 2024, at
        20:00:00, a sudden price spike occurred due to an MEV (Maximal
        Extractable Value) bot. These bots detect purchase transactions and
        front-run them by paying higher gas fees to execute their transactions
        first. In this incident, the MEV bot bought tokens before the intended
        buyer, causing a temporary price surge. The buyer then purchased at the
        inflated price, and the bot immediately sold its tokens for a profit,
        all within the same block. This manipulation resulted in a false spike
        in the price. To prevent such occurrences, reducing the slippage setting
        when purchasing tokens can be effective.
      </p>

      <p>
        <strong>June 14th, 2024 - Seller Cash-Out: </strong>June 14, 2024, saw
        another selloff as three big investors cashed out. The price stabilized
        and slowly started to heal. In the month of June, the average burn rate
        was $20.30. As will be shown, it did have an impact on the price, albeit
        minor.
      </p>

      <p>
        <strong>June 29th, 2024 - Marketing Blitz: </strong>TradeBot was listed
        on SolTrending and had 100,000 banner impressions on the DexScreener
        website. Price increased approximately 20% and our Telegram channel
        doubled in size from ~160 to ~360 members. The rise in price during this
        24-hour period can be attributed to marketing.
      </p>

      <h2>$TRADEBOT Projections with Self-Healing Technology</h2>
      <p>
        The ATH Price and the Simulation Start Price provide a baseline for
        evaluating the impact of different daily buy/burn rates. The projected
        prices for each burn rate scenario demonstrate that higher burn rates
        lead to significantly higher token prices, far exceeding the ATH
        observed in the real data. This analysis highlights the potential of
        TradeBot's Self-Healing Technology to create substantial value for token
        holders through sustained buy and burn practices. The data shows that
        even a modest daily burn rate can lead to noticeable price increases,
        while higher buy / burn rates can dramatically enhance the token's value
        by adding more Solana to the Liquidity Pool.
      </p>
      <h3>Assumptions</h3>
      <ul>
        <li>
          <strong>Daily Buy and Burn: </strong>A consistent amount of $TRADEBOT
          is bought and burned each day.
        </li>
        <li>
          <strong>No New Traders: </strong>We assume no new traders are entering
          the market.
        </li>
        <li>
          <strong>No Current Traders Exiting: </strong>We assume that current
          holders do not sell their holdings.
        </li>
        <li>
          <strong>Market Conditions: </strong>During the simulation, Solana
          pricing stays consistent at 138.8559.
        </li>
        <li>
          <strong>No Catastrophic Failures: </strong>Volatility drives the
          trading engine and thus the healing process, but catastrophic failures
          of BTC, SOL, or USDC would break it.
        </li>
      </ul>
      <h3>Simulation Parameters</h3>
      <ul>
        <li>
          <strong>Real Data:</strong> The simulation uses real transaction data
          up to 2024-06-30T02:38:07Z.
        </li>
        <li>
          <strong>Simulation Start:</strong> The simulation starts from
          2024-07-01.
        </li>
        <li>
          <strong>Supply At Simulation Start:</strong> 998,750,100.332763
          $TRADEBOT
        </li>
        <li><strong>Price At Simulation Start:</strong> $0.0008955</li>
        <li>
          <strong>Daily Buy and Burn Amounts:</strong> $20, $100, $500, $1000,
          $10000
        </li>
        <li><strong>Chart Duration:</strong> 1 year (365 days)</li>
        <li>
          <strong>Simulation Duration:</strong> July 1st 2024 - April 30th 2025
          (303 days)
        </li>
        <li>
          <strong>Liquidity Pool Settings At Simulation Start:</strong>
          <ul>
            <li>326.835552840 SOL</li>
            <li>50,795,702.988853 $TRADEBOT</li>
            <li>SOL Amount (USD): 326.835552840 * 138.8559 ≈ $45,383.044</li>
          </ul>
        </li>
        <li><strong>1,000,000 $TRADEBOT: </strong>$895.50</li>
      </ul>
      <h2>Projection Analysis</h2>
      <p>
        In this section, we aim to evaluate the impact of TradeBot's
        Self-Healing Technology at various daily buy and burn rates. By
        simulating different daily buy amounts—$20, $100, $500, $1000, and
        $10000—we can observe how each rate influences the price and supply
        dynamics of $TRADEBOT over a year. This analysis helps illustrate the
        potential effectiveness of the Self-Healing Technology in different
        scenarios.
      </p>

      <p>
        It is important to note that in a real-world scenario, the daily burn
        amount would be best represented as an average across time. This
        approach provides a more realistic view of how the Self-Healing
        Technology functions under typical market conditions.
      </p>

      <h3>$20 TradeBot Burn Rate</h3>

      <p>
        At a $20 daily burn rate, the price of $TRADEBOT is projected to reach
        $0.00115088 by 2025-04-30. This minor price increase does not
        significantly enhance investor value over time. Interestingly, this rate
        closely matches the average daily burn rate of $20.30 observed in June
        2024. June was a slow month due to market conditions. If this rate
        continues, for $TRADEBOT to grow substantially, consistent burn rates
        must be coupled with effective marketing and community outreach.
      </p>

      <ul>
        <li>
          <strong>Liquidity Pool Status: </strong>
          <ul>
            <li>370.621951768 SOL</li>
            <li>44,808,293.694979 $TRADEBOT</li>
            <li>SOL Amount (USD): 370.621951768 * 138.8559 ≈ $51,492.087</li>
          </ul>
        </li>
        <li><strong>Total $TRADEBOT Burned: </strong>5,987,409</li>
        <li><strong>Percentage of Total Supply Burned: </strong>0.5987%</li>
        <li><strong>End Date: </strong>2025-04-30</li>
        <li><strong>Closing Price: </strong>$0.00115088</li>
        <li><strong>1,000,000 $TRADEBOT: </strong>$1,150.88</li>
      </ul>
      <CandleChart
        filename="tradebot-20.js"
        title="$TRADEBOT with $20 daily heal rate"
      ></CandleChart>

      <h3>$100 TradeBot Burn Rate</h3>
      <p>
        With a $100 daily burn rate, the price increases to $0.00249097 by
        2025-04-30. This is significantly better than the $20 burn rate with the
        price breaching the previous ATH price of $.00201724 on Feb. 14th 2025.
        By the end of this period, all wallet holders are in the green.
      </p>
      <ul>
        <li>
          <strong>Liquidity Pool Status: </strong>
          <ul>
            <li>545.767548696 SOL</li>
            <li>30,457,323.659142 $TRADEBOT</li>
            <li>SOL Amount (USD): 545.767548696 * 138.8559 ≈ $75,783.044</li>
          </ul>
        </li>
        <li><strong>Total $TRADEBOT Burned: </strong>20,338,379</li>
        <li><strong>Percentage of Total Supply Burned: </strong>2.0338%</li>
        <li><strong>End Date: </strong>2025-04-30</li>
        <li><strong>Closing Price: </strong>$0.00249097</li>
        <li><strong>1,000,000 $TRADEBOT: </strong>$2,490.97</li>
      </ul>

      <CandleChart
        filename="tradebot-100.js"
        title="$TRADEBOT with $100 daily heal rate"
      ></CandleChart>

      <h3>$500 TradeBot Burn Rate</h3>
      <p>
        A $500 daily burn rate results in a closing price of $0.01683856,
        demonstrating significant price appreciation. Traders who bought at the
        previous all-time high (ATH) of $0.00201724 would enjoy an impressive
        ROI of 734%. Those who purchased at the simulation start price of
        $0.0008955 would see an extraordinary ROI of 1780%. Breaking the $0.01
        price barrier is also a notable psychological milestone.
      </p>

      <ul>
        <li>
          <strong>Liquidity Pool Status: </strong>
          <ul>
            <li>1,421.495533336 SOL</li>
            <li>11,721,006.875546 $TRADEBOT</li>
            <li>SOL Amount (USD): 1,421.495533336 * 138.8559 ≈ $197,383.041</li>
          </ul>
        </li>
        <li><strong>Total $TRADEBOT Burned: </strong>39,074,696</li>
        <li><strong>Percentage of Total Supply Burned: </strong>3.9075%</li>
        <li><strong>End Date: </strong>2025-04-30</li>
        <li><strong>Closing Price: </strong>$0.01683856</li>
        <li><strong>1,000,000 $TRADEBOT: </strong>$16,838.56</li>
      </ul>
      <CandleChart
        filename="tradebot-500.js"
        title="$TRADEBOT with $500 daily heal rate"
      ></CandleChart>

      <h3>$1000 TradeBot Burn Rate</h3>
      <p>
        At a $1000 daily burn rate, the price of $TRADEBOT soars to $0.05266788
        by the end of the simulation. During this period, 44,164,798.577405
        $TRADEBOT tokens were burned, amounting to approximately 4.4165% of the
        initial supply. The liquidity pool ends with 2,516.155514136 SOL and
        6,630,904.411448 $TRADEBOT, showing a dramatic decrease in supply and a
        corresponding increase in price. Traders who bought at the previous
        all-time high (ATH) of $0.00201724 would enjoy a hefty ROI of 2510%.
        Those who purchased at the simulation start price of $0.0008955 would
        see an incredible ROI of 5781%!
      </p>
      <ul>
        <li>
          <strong>Liquidity Pool Status: </strong>
          <ul>
            <li>2,516.155514136 SOL</li>
            <li>6,630,904.411448 $TRADEBOT</li>
            <li>SOL Amount (USD): 2,516.155514136 * 138.8559 ≈ $349,348.429</li>
          </ul>
        </li>
        <li><strong>Total $TRADEBOT Burned: </strong>44,164,798</li>
        <li><strong>Percentage of Total Supply Burned: </strong>4.4165%</li>
        <li><strong>End Date: </strong>2025-04-30</li>
        <li><strong>Closing Price: </strong>$0.05266788</li>
        <li><strong>1,000,000 $TRADEBOT: </strong>$52,667.88</li>
      </ul>
      <CandleChart
        filename="tradebot-1000.js"
        title="$TRADEBOT with $1000 daily heal rate"
      ></CandleChart>

      <h3>$10000 TradeBot Burn Rate</h3>
      <p>
        The $10000 daily burn rate scenario projects an astonishing price of
        $4.08494478 by 2025-04-30. During the simulation period,
        50,041,002.540019 $TRADEBOT tokens were burned, representing 5.0041% of
        the initial supply. The liquidity pool contains 22,220.035168536 SOL and
        754,700.448834 $TRADEBOT. This aggressive burn rate drastically reduces
        the token supply, driving the price to unprecedented levels. Traders who
        bought at the previous all-time high (ATH) of $0.00201724 would
        experience a staggering ROI of 202,401%, while those who purchased at
        the simulation start price of $0.0008955 would see an extraordinary ROI
        of 456,063%. However, there is a liquidity issue. At this price point, a
        theoretical holder of 1,000,000 $TRADEBOT has a value greater than the
        total Solana in the liquidity pool. To address this, the community would
        need to add liquidity, or the holder would need to sell small amounts
        periodically over time to allow other traders to replenish the SOL supply.
        In this manner, the theoretical investor could obtain their total value with minimal slippage.
      </p>

      <ul>
        <li>
          <strong>Liquidity Pool Status: </strong>
          <ul>
            <li>22,220.035168536 SOL</li>
            <li>754,700.448834 $TRADEBOT</li>
            <li>
              SOL Amount (USD): 22,220.035168536 * 138.8559 ≈ $3,085,382.981
            </li>
          </ul>
        </li>
        <li><strong>Total $TRADEBOT Burned: </strong>50,041,002</li>
        <li><strong>Percentage of Total Supply Burned: </strong>5.0041%</li>
        <li><strong>End Date: </strong>2025-04-30</li>
        <li><strong>Closing Price: </strong>$4.08494478</li>
        <li><strong>1,000,000 $TRADEBOT: </strong>$4,084,944.78</li>
      </ul>
      <CandleChart
        filename="tradebot-10000.js"
        title="$TRADEBOT with $10000 daily heal rate"
      ></CandleChart>
      
      <h3>Summary</h3>
      <p>
     The projected
        prices for each burn rate scenario demonstrate that higher burn rates
        lead to significantly higher token prices, far exceeding the ATH
        observed in the real data. This analysis highlights the potential of
        TradeBot's Self-Healing Technology to create substantial value for token
        holders through strategic and sustained buy and burn practices. The data
        shows that even a modest daily burn rate can lead to noticeable price
        increases, while higher burn rates can dramatically enhance the token's
        value.
      </p>

      <h2>Conclusion</h2>
      <p>
        TradeBot's self-healing technology offers a promising solution to the
        inherent volatility and risks of memecoins. By leveraging consistent
        revenue streams to buy and burn tokens, TradeBot aims to stabilize and
        increase token value, creating a more equitable environment for
        investors. The detailed case studies of the $BRETT and $TRADEBOT tokens illustrate how
        this innovative approach can transform the memecoin landscape, reducing
        the zero-sum nature of these investments.
      </p>

      <p>
        We invite new investors to join the TradeBot community and participate
        in building a sustainable and prosperous ecosystem.
      </p>

      <h2>Disclaimer</h2>
      <p>
        It is important to note that $TRADEBOT is a community-owned memecoin,
        relying heavily on the active participation and support of its community
        members. Inherently, $TRADEBOT possesses no intrinsic value and should
        be regarded primarily as an entertainment token. Prospective investors
        are encouraged to approach it with the understanding that its value is
        largely driven by community engagement and speculative interest. 
      </p>
    </section>
  </section>
</template>

<script>
import BrettChart from "../components/BrettChart.vue";
import CandleChart from "../components/CandleChart.vue";
import HistogramChart from "../components/HistogramChart.vue";
import PortfolioHistogram from "../components/PortfolioHistogram.vue";

export default {
  name: "Whitepaper",
  components: { BrettChart, CandleChart, HistogramChart, PortfolioHistogram },
};
</script>

<style scoped>
.padding {
  padding-left: 10px;
  padding-right: 10px;
}

.figures {
  margin-top: 30px;
  width: 80%;
}

li {
  text-align: left;
}

ul {
  margin-left: 10%;
  margin-right: 10%;
  width: auto;
  max-width: 100%;
}

caption {
  margin-bottom: 30px;
}
</style>
