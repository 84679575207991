<template>
    <v-chart class="chart" :option="option" autoresize />
</template>

<script setup>
import { use } from 'echarts/core'
import { BarChart, CandlestickChart } from 'echarts/charts'
import {
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    DataZoomComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import VChart, { THEME_KEY } from 'vue-echarts';
import { ref, provide, onMounted } from 'vue';
import dataService from '../services/DataService';

use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    DataZoomComponent,
    BarChart,
    CandlestickChart,
    CanvasRenderer
]);

provide(THEME_KEY, 'dark');

const props = defineProps({
    filename: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    }
});

const option = ref({});

const loadChartData = async () => {
    const candleData = await dataService.loadCandleData(props.filename, props.title);

    option.value = {
        title: {
            text: candleData.title,
            left: 'center',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: "line",
            },
        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: false,
                },
            },
        },
        grid: [
            {
                left: "10%",
                right: "10%",
                bottom: 200,
            },
            {
                left: "10%",
                right: "10%",
                height: 80,
                bottom: 80,
            },
        ],
        xAxis: [
            {
                type: 'category',
                data: candleData.dates,
                scale: true,
                boundaryGap: true,
                // inverse: true,
                axisLine: { onZero: false },
                splitLine: { show: false },
                splitNumber: 20,
                min: "dataMin",
                max: "dataMax",
            }, {
                type: "category",
                data: candleData.dates,
                gridIndex: 1,
                scale: true,
                boundaryGap: false,
                axisLine: { onZero: false },
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: { show: false },
                splitNumber: 20,
                min: "dataMin",
                max: "dataMax",
            }
        ],
        yAxis: [
            {
                name: 'Price (USD)',
                nameLocation: 'middle',
                nameGap: 60,
                scale: true,
                splitArea: {
                    show: true,
                },
            }, {         
                scale: true,
                gridIndex: 1,
                splitNumber: 2,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: true },
            },
            {                
                scale: true,
                gridIndex: 1,
                splitNumber: 2,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: true },
            }
        ],
        dataZoom: [
            {
                type: "inside",
                xAxisIndex: [0, 1],
                start: 0,
                end: 100,
            },
            {
                show: true,
                xAxisIndex: [0, 1],
                type: "slider",
                bottom: 10,
                start: 0,
                end: 100,
            },
        ],
        series: [
            {
                name: "Volume",
                type: "bar",
                xAxisIndex: 1,
                yAxisIndex: 1,
                itemStyle: {
                    color: "#7fbe9e",
                },
                emphasis: {
                    itemStyle: {
                        color: "#140",
                    },
                },
                data: candleData.volumes
            },
            {                
                type: 'candlestick',
                data: candleData.data,
                itemStyle: {
                    color: "#14b143",
                    color0: "#ef232a",
                    borderColor: "#14b143",
                    borderColor0: "#ef232a",
                },
                emphasis: {
                    itemStyle: {
                        color: "black",
                        color0: "#444",
                        borderColor: "black",
                        borderColor0: "#444",
                    },
                },
                encode: {
                    x: 0,
                    y: [1, 4, 3, 2],
                },
            }
        ]
    };
};
onMounted(loadChartData);
</script>

<style scoped>
.chart {
    height: 70vh;
    width: 100%;
}
</style>