<template>
  <section class="view">
    <section class="container">
      <section class="text-flex">
        <div class="title-flex">
          <h1 class="title">Experienced Crypto Traders</h1>
          <jupiter-button></jupiter-button>
        </div>

        <div class="stack" style="--stacks: 3">
          <span style="--index: 0">
            <img
              class="pointing-bot"
              src="../assets/pointing-bot.png"
              alt="robot pointing to the left"
          /></span>
          <span style="--index: 1">
            <img
              class="pointing-bot"
              src="../assets/pointing-bot.png"
              alt="robot pointing to the left"
          /></span>
          <span style="--index: 2">
            <img
              class="pointing-bot"
              src="../assets/pointing-bot.png"
              alt="robot pointing to the left"
          /></span>
        </div>
        <div class="stack" style="--stacks: 3">
          <span style="--index: 0">
            <img
              class="idea-bot"
              src="../assets/idea-robot.png"
              alt="robot pointing to a blue lightbulb"
          /></span>
          <span style="--index: 1">
            <img
              class="idea-bot"
              src="../assets/idea-robot.png"
              alt="robot pointing to a blue lightbulb"
          /></span>
          <span style="--index: 2">
            <img
              class="idea-bot"
              src="../assets/idea-robot.png"
              alt="robot pointing to a blue lightbulb"
          /></span>
        </div>
      </section>
      <h2>Need Help Buying?</h2>
      <p class="subtle-glitch">
        <span aria-hidden="true"
          >There are <b>3 simple steps</b> to purchasing
          <span class="tradebot-highlight">$TRADEBOT:</span></span
        >
        There are <b>3 simple steps</b> to purchasing
        <b>$TRADEBOT:</b>
        <span aria-hidden="true"
          >There are <b>3 simple steps</b> to purchasing
          <span class="tradebot-highlight">$TRADEBOT:</span></span
        >
      </p>

      <h2 class="hover-glitch">1. Create a wallet</h2>
      <p>
        We recommend installing and using the Phantom Wallet on your Chrome
        Browser.
      </p>
      <ul>
        <li>
          <span class="blue">Link: </span
          ><a href="https://phantom.app" target="_blank">https://phantom.app</a>
        </li>
        <li>
          <span class="blue">Tutorial Video: </span
          ><a href="https://youtu.be/vIRIJcOJbvI?t=25" target="_blank"
            >How to install Phantom Wallet</a
          >
        </li>
      </ul>
      <div class="stack" style="--stacks: 3">
        <span style="--index: 0">
          <h2>2. Purchase $SOL to put in your wallet.</h2></span
        >
        <span style="--index: 1">
          <h2>2. Purchase $SOL to put in your wallet.</h2></span
        >
        <span style="--index: 2">
          <h2>2. Purchase $SOL to put in your wallet.</h2></span
        >
      </div>
      <p>
        Due to varying national and state regulations some of these methods
        might not work. Many major exchanges have a waiting period of 10 days
        before you can use your newly purchased crypto. The tutorials below show
        you many different ways to purchase $SOL so that you can use it
        immediately to buy $TRADEBOT.
      </p>
      <ul>
        <li>
          <span class="blue">Tutorial Video: </span
          ><a href="https://www.youtube.com/watch?v=VWmXnALYsxg" target="_blank"
            >How to use PayPal and Coinbase to purchase $SOL for immediate
            use.</a
          >
        </li>
        <li>
          <span class="blue">Tutorial Video: </span
          ><a href="" target="_blank"
            >How to use Phantom Wallet to purchase $SOL for immediate use.</a
          >
        </li>
      </ul>

      <p class="subtle-glitch">
        <span aria-hidden="true"
          >In the case these methods don't work, purchase $SOL on your major
          exchange and withdrawal to your Phantom wallet after the waiting
          period is complete.</span
        >
        In the case these methods don't work, purchase $SOL on your major
        exchange and withdrawal to your Phantom wallet after the waiting period
        is complete.
        <span aria-hidden="true"
          >In the case these methods don't work, purchase $SOL on your major
          exchange and withdrawal to your Phantom wallet after the waiting
          period is complete.</span
        >
      </p>

      <h2>3. Go to Jupiter Swap to purchase $TRADEBOT</h2>
      <ul>
        <li>
          <span class="blue">Tutorial Video: </span
          ><a href="https://youtu.be/VWmXnALYsxg?t=420" target="_blank"
            >How to purchase $TRADEBOT using Phantom Wallet</a
          >
        </li>
        <li>
          <span class="blue">Tutorial Video: </span
          ><a href="https://youtu.be/vIRIJcOJbvI?t=608" target="_blank"
            >How to purchase $TRADEBOT using Phantom Wallet Alternate Video</a
          >
        </li>
      </ul>
      <jupiter-button class="margin"></jupiter-button>
      <h2>Want to talk to someone?</h2>
      <a class="buy" href="https://discord.gg/kmB6cJwun9" target="_BLANK">
        <div class="button-row shake">
          <p class="buy">Join our</p>
          <img
            class="discord-logo"
            src="../assets/discord.svg"
            alt="discord logo"
          />
        </div>
      </a>
    </section>
    <disclaimer-component></disclaimer-component>
  </section>
</template>

<script>
import DisclaimerComponent from '../components/DisclaimerComponent.vue';
import JupiterButton from '@/components/JupiterButton.vue';

export default {
  components: { DisclaimerComponent, JupiterButton },
  name: "BuyView",
};
</script>

<style scoped>
.margin {
  margin-top: 1%;
}

.stack span {
  font-size: 16px;
}

.discord-logo {
  width: 100px;
}

.title {
  margin-bottom: 5%;
}

ul {
  width: 80%;
}

.text-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pointing-bot {
  width: 150px;
}

.idea-bot {
  width: 100px;
  margin-bottom: 3%;
}

.list {
  text-align: left;
  padding: 10px;
  margin-bottom: 3%;
}

li {
  text-align: left;
  line-height: 35px;
}

.blue {
  font-weight: bold;
}

.button-row {
  margin-top: 5%;
  margin-bottom: 8%;
}

@media only screen and (max-width: 768px) {
  .pointing-bot {
    display: none;
  }

  h2 {
    font-size: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (min-width: 768px) {
  .text-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin-bottom: 1%;
  }

  .idea-bot {
    display: none;
  }

  li {
    line-height: 40px;
  }
}
</style>
