<template>
  <section class="view">
    <div class="header">
      <h1>Welcome to TradeBot</h1>
      <img class="sign-robot shake fly" src="../assets/waving-robot.png"
        alt="a blue and white robot flying through space" />
    </div>

    <div class="container">
      <div class="slogan-container">
        <h2>          
          <span class="stack" style="--stacks: 3">
            <span style="--index: 0">Building Generational Wealth</span>
            <span style="--index: 1">Building Generational Wealth</span>
            <span style="--index: 2">Building Generational Wealth</span>
          </span>
        </h2>
      </div>

      <vue-plyr :options="options">
        <video controls crossorigin playsinline poster="../assets/video-thumbnail.png">
          <source src="../assets/explanation-video.mp4" type="video/mp4" />
          <!-- <track
      default
      kind="captions"
      label="English captions"
      src="/path/to/english.vtt"
      srclang="en"
    /> -->
        </video>
      </vue-plyr>


      <h3>What is TradeBot?</h3>
      <p>
        TradeBot is a cutting-edge cryptocurrency on the Solana blockchain, designed to be fueled by the community through innovative, automated revenue streams.
        Community revenue is funneled into a powerful proprietary trading engine, generating consistent monthly 
        profits of 1-8%. These profits are reinvested to buy and burn <span class="tradebot-highlight">$TRADEBOT</span> tokens, solidifying sustained value and protecting 
        our holders' interests. The more we feed the engine, the higher the burn rate, creating a deflationary model that 
        benefits everyone. Our mission is to build a token with sustainable growth, aiming to create generational wealth for all
         holders.
         </p>  

      <h3 class="subtle-glitch">
        <span aria-hidden="true">Join Us!</span>
        Join Us!
        <span aria-hidden="true">Join Us!</span>
      </h3>
      <p>
        Are you ready to embrace the innovation behind TradeBot? Whether you're
        a seasoned crypto enthusiast or just someone looking for a thrill, the
        TradeBot community welcomes you with open arms!
      </p>
      <section class="button-flex">
        <jupiter-button></jupiter-button>
        <router-link class="buy" to="/buy">
          <div class="button-row">
            <p class="buy">Need Help Buying?</p>
            <img class="avatar" src="../assets/tradebot-avatar.png" alt="tradebot avatar" />
          </div>
        </router-link>
      </section>
    </div>
    <disclaimer-component></disclaimer-component>
  </section>
</template>

<script>
import DisclaimerComponent from "../components/DisclaimerComponent.vue";
import JupiterButton from "../components/JupiterButton.vue";

export default {
  name: "HomeView",
  components: {
    DisclaimerComponent,
    JupiterButton
  },
};
</script>

<style scoped>
/* start of code for fly: */

video {
  width: 80%;
}

.fly {
  animation: fly 15s;
  animation-iteration-count: infinite;
}

@keyframes fly {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-5deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(5deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(5deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-5deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-5deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(5deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* end of fly code ^ */

.slogan-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.sign-robot {
  width: 100px;
}

h2 {
  width: 80%;
  margin-top: 3%;
  text-shadow: 5px 5px 5px black;
}

p {
  margin-top: 5px;
}

.buy {
  margin: 0px;
  margin-bottom: 5px;
}

.button-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6%;
}

@media only screen and (max-width: 330px) {
  h1 {
    font-size: 20px;
  }

  video {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .sign-robot {
    display: none;

  
  }

  .header {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media only screen and (min-width: 900px) {
  .header {
    margin-top: 4%;
    gap: 15px;
  }

  .container {
    margin-top: 3%;
  }

  .button-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 3%;
  }

  .sign-robot {
    width: 150px;
  }
}
</style>
