import { createApp } from 'vue';
import App from './App.vue';
import '../public/css/global.css'; 
import router from './router/index.js';

/* import fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBars, faUser, faUsers, faUserCircle, faTrashCan, faPencil, faCirclePlus, faTriangleExclamation, faXmark, faMagnifyingGlass, faChessRook, faBookOpen, faScrewdriverWrench, faClipboardList, faBug, faFloppyDisk, faVideo } from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(faUserCircle)
library.add(faUser)
library.add(faUsers)
library.add(faTrashCan)
library.add(faPencil)
library.add(faCirclePlus)
library.add(faTriangleExclamation)
library.add(faXmark)
library.add(faMagnifyingGlass)
library.add(faChessRook);
library.add(faBookOpen);
library.add(faScrewdriverWrench);
library.add(faClipboardList)
library.add(faBug)
library.add(faFloppyDisk)
library.add(faVideo)
library.add(faBars)

const app = createApp(App);
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app');
