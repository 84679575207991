<template>
    <v-chart class="chart" :option="option" autoresize />
</template>

<script setup>
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import VChart, { THEME_KEY } from 'vue-echarts';
import { ref, provide, onMounted } from 'vue';
import dataService from '../services/DataService';

use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer])

provide(THEME_KEY, 'dark');

// const props = defineProps({
//     filename: {
//         type: String,
//         required: true
//     },
//     title: {
//         type: String,
//         required: true
//     }
// });

const option = ref({});

const loadChartData = async () => {
    //const histogramData = await dataService.loadHistogramData(props.filename, props.title);

    option.value = {
    title: {
        text: 'State of $BRETT Investors - 6/26/2024',
        left: 'center'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    xAxis: {
        type: 'category',
        data: ['SOLD ALL\nFOR LOSS', 'SOLD ALL\nFOR PROFIT', 'HOLDS\nIN LOSS', 'HOLDS\nIN GAIN'],
        axisLabel: {
            overflow: 'truncate', // or 'break' to continue in a new line
            interval: 0,
        }
    },
    yAxis: {
        type: 'value',
        name: 'Number of Investors',
        axisLabel: {
            formatter: '{value}'            
        }
    },
    series: [{
        name: 'Number of Investors',
        type: 'bar',
        data: [646, 549, 524, 112],
        itemStyle: {
            color: (params) => {
                const colors = {
                    'SOLD ALL\nFOR LOSS': '#8B0000',  // Red color
                    'SOLD ALL\nFOR PROFIT': '#006600',  // Green color
                    'HOLDS\nIN LOSS': '#ff0000',  // Light red color
                    'HOLDS\nIN GAIN': '#00ff00'  // Light green color
                };
                return colors[params.name];
            }
        }
    }]
};
};

onMounted(loadChartData);
</script>

<style scoped>
.chart {
    height: 50vh;
    width: 100%;
}
</style>