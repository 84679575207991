<template>
  <section class="view">
   <section class="container">
        <h1>Hello CoinMarketCap!</h1>
      <p>
        Please update description, circulating supply, and add whitepaper link.
      </p>
      <img src="../assets/thumbs-up-bot.png" alt="robot with thumbs up" class="tradebot-size">
    </section>
</section>
</template>

<style scoped>


.tradebot-size {
    margin-bottom: 10px;
}

</style>
