<template>
  <div>
    <div id="tradebot">
      <app-header id="app-header"></app-header>
      <main id="main-content">
        <router-view></router-view>
      </main>
      <app-footer id="app-footer"></app-footer>
    </div>
    <!-- <div class="fuzzy-overlay"></div> -->
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Jersey+15&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

#app {
  font-family: "Playfair Display", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  overflow: hidden;
}

#tradebot {
  position: relative;
  z-index: 5;
}

.fuzzy-overlay {
  position: absolute;
  inset: -200%;
  background-image: url("https://t3.ftcdn.net/jpg/03/07/88/64/360_F_307886432_FbDT1fR3JwoQv1oaQdyaWNFBpiJE6w0C.jpg");
  z-index: 10;
  pointer-events: none;
  animation: shift 0.2s linear infinite both, pulse 20s infinite;
  /* both: bottom right to top left and reverse */
}

@keyframes shift {
  0% {
    transform: translateX(10%) translateY(10%);
  }
  100% {
    transform: translateX(-10%) translateY(-10%);
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0%;
  }

  50% {
    opacity: 20%;
  }
}
</style>
