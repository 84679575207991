<template>
  <section class="view">
    <section class="container">
      <section class="hero-container">
        <div class="environment"></div>
        <h2 class="hero glitch layers" data-text="Tokenomics">
          <span>Tokenomics</span>
        </h2>
      </section>
      <ul class="list">
        <li>
          Token Address:
          <span class="token-address"
            >GaRph5BcLZZ4sRh6EXKS6V7dicVanhcwa5iWEE6AbEYR</span
          >
        </li>
        <li>Blockchain: Solana</li>
        <li>Initial Supply: 1,000,000,000</li>
        <li>Contract Renounced</li>
        <li>LP Tokens burned</li>
      </ul>
      <img
        class="tradebot-size shake"
        src="../assets/top-sign.png"
        alt="robot looking over text"
      />

      <div class="stack margin" style="--stacks: 3">
        <span style="--index: 0">
          <p>
            <b>$TRADEBOT</b>
            is a memecoin hosted on the <b>Solana blockchain</b>.
            <b>$TRADEBOT</b> was launched on the pump.fun website on 2024-04-27
            as a fair-launch with no presale and no team allocation. The initial
            total circulating supply was 1 billion tokens and the developers
            purchased ~220 million tokens upfront to fund marketing, operations,
            and further development work.
          </p></span
        >
        <span style="--index: 1">
          <p>
            <b>$TRADEBOT</b>
            is a memecoin hosted on the <b>Solana blockchain</b>.
            <b>$TRADEBOT</b> was launched on the pump.fun website on 2024-04-27
            as a fair-launch with no presale and no team allocation. The initial
            total circulating supply was 1 billion tokens and the developers
            purchased ~220 million tokens upfront to fund marketing, operations,
            and further development work.
          </p></span
        >
        <span style="--index: 2">
          <p>
            <b>$TRADEBOT</b>
            is a memecoin hosted on the <b>Solana blockchain</b>.
            <b>$TRADEBOT</b> was launched on the pump.fun website on 2024-04-27
            as a fair-launch with no presale and no team allocation. The initial
            total circulating supply was 1 billion tokens and the developers
            purchased ~220 million tokens upfront to fund marketing, operations,
            and further development work.
          </p></span
        >
      </div>
      <div class="stack" style="--stacks: 3">
        <span style="--index: 0">
          <img
            class="tradebot-size"
            src="../assets/dancing-bot.png"
            alt="robot dancing while wearing a blue party hat"
        /></span>
        <span style="--index: 1">
          <img
            class="tradebot-size"
            src="../assets/dancing-bot.png"
            alt="robot dancing while wearing a blue party hat"
        /></span>
        <span style="--index: 2">
          <img
            class="tradebot-size"
            src="../assets/dancing-bot.png"
            alt="robot dancing while wearing a blue party hat"
        /></span>
      </div>
      <ul class="list">
        <li>50 million $TRADEBOT was allocated to marketing.</li>
        <li>
          50 million $TRADEBOT was allocated to operations such as cloud costs,
          website development, and social media tools.
        </li>
        <li>
          120 million $TRADEBOT was allocated to the the development and
          operation of the core trading and burning algorithms.
        </li>
      </ul>
      <h2 class="hide">$TRADEBOT Tokenomics</h2>
      <div id="container">
        <h1 class="glitch-text">$TRADEBOT Tokenomics</h1>
        <h1 class="glitch-text">$TRADEBOT Tokenomics</h1>
        <h1 class="glitch-text">$TRADEBOT Tokenomics</h1>
      </div>
      <tokenomics-chart class="chart"></tokenomics-chart>
      <div class="stack margin" style="--stacks: 3">
        <span style="--index: 0"
          ><p>
            On 2024-04-30,
            <b>$TRADEBOT</b> graduated the incubation period and a $SOL /
            <b>$TRADEBOT</b> Liquidity Pool was created on Raydium. The LP
            tokens for this deposit were burned. No one can pull funds from the
            liquidity pool, thus safeguarding all <b>$TRADEBOT</b> token
            holders.
          </p></span
        >
        <span style="--index: 1"
          ><p>
            On 2024-04-30,
            <b>$TRADEBOT</b> graduated the incubation period and a $SOL /
            <b>$TRADEBOT</b> Liquidity Pool was created on Raydium. The LP
            tokens for this deposit were burned. No one can pull funds from the
            liquidity pool, thus safeguarding all <b>$TRADEBOT</b> token
            holders.
          </p></span
        >
        <span style="--index: 2"
          ><p>
            On 2024-04-30,
            <b>$TRADEBOT</b> graduated the incubation period and a $SOL /
            <b>$TRADEBOT</b> Liquidity Pool was created on Raydium. The LP
            tokens for this deposit were burned. No one can pull funds from the
            liquidity pool, thus safeguarding all <b>$TRADEBOT</b> token
            holders.
          </p></span
        >
      </div>

      <p>
        On 2024-05-07, the first
        <span class="tradebot-highlight">$TRADEBOT</span> tokens were purchased
        and burned from revenue generated by the TradeBot. To view this
        transaction and all subsequent burn transactions, refer to the
        <router-link v-on:click="disableMobileNav" class="link" to="/burns"
          >burns</router-link
        >
        page.
      </p>
      <jupiter-button class="btn-margin"></jupiter-button>
    </section>
    <disclaimer-component></disclaimer-component>
  </section>
</template>

<script>
import DisclaimerComponent from "../components/DisclaimerComponent.vue";
import TokenomicsChart from "../components/TokenomicsChart.vue";
import JupiterButton from "@/components/JupiterButton.vue";

export default {
  name: "TokenView",
  components: { TokenomicsChart, DisclaimerComponent, JupiterButton },
};
</script>

<style scoped>
.btn-margin {
  margin-bottom: 1%;
}

.token-address {
  font-size: 5px;
}

.margin {
  margin-left: 5%;
}

.list {
  text-align: left;
  line-height: 40px;
  width: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

/* start of code for title glitch */
.hero {
  font-size: clamp(35px, 10vw, 30px);
  line-height: 1;
  display: inline-block;
  color: #fff;
  z-index: 2;
  letter-spacing: 10px;

  /* Bright things in dark environments usually cast that light, giving off a glow */
  filter: drop-shadow(0 1px 3px);
}

.layers {
  position: relative;
}

.layers::before,
.layers::after {
  content: attr(data-text);
  position: absolute;
  width: 10%;
  z-index: -1;
}

.layers::before {
  top: 40px;
  left: 25px;
  color: #e0287d;
}

.layers::after {
  top: 5px;
  left: -50px;
  color: #1bc7fb;
}

.single-path {
  clip-path: polygon(
    0% 12%,
    53% 12%,
    53% 26%,
    25% 26%,
    25% 86%,
    31% 86%,
    31% 0%,
    53% 0%,
    53% 84%,
    92% 84%,
    92% 82%,
    70% 82%,
    70% 29%,
    78% 29%,
    78% 65%,
    69% 65%,
    69% 66%,
    77% 66%,
    77% 45%,
    85% 45%,
    85% 26%,
    97% 26%,
    97% 28%,
    84% 28%,
    30% 89%,
    30% 58%,
    83% 58%,
    83% 5%,
    68% 5%,
    68% 36%,
    62% 36%,
    62% 1%,
    12% 1%,
    12% 34%,
    60% 34%,
    60% 57%,
    98% 57%,
    98% 83%,
    1% 83%,
    1% 53%,
    91% 53%,
    91% 84%,
    8% 84%,
    8% 83%,
    4% 83%
  );
}

.paths {
  animation: paths 20s step-end infinite;
}

@keyframes paths {
  0% {
    clip-path: polygon(
      0% 43%,
      83% 43%,
      83% 22%,
      23% 22%,
      23% 24%,
      91% 24%,
      91% 26%,
      18% 26%,
      18% 83%,
      29% 83%,
      29% 17%,
      41% 17%,
      41% 39%,
      18% 39%,
      18% 82%,
      54% 82%,
      54% 88%,
      19% 88%,
      19% 4%,
      39% 4%,
      39% 14%,
      76% 14%,
      76% 52%,
      23% 52%,
      23% 35%,
      19% 35%,
      19% 8%,
      36% 8%,
      36% 31%,
      73% 31%,
      73% 16%,
      1% 16%,
      1% 56%,
      50% 56%,
      50% 8%
    );
  }

  5% {
    clip-path: polygon(
      0% 29%,
      44% 29%,
      44% 83%,
      94% 83%,
      94% 56%,
      11% 56%,
      11% 64%,
      94% 64%,
      94% 70%,
      88% 70%,
      88% 32%,
      18% 32%,
      18% 96%,
      10% 96%,
      10% 62%,
      9% 62%,
      9% 84%,
      68% 84%,
      68% 50%,
      52% 50%,
      52% 55%,
      35% 55%,
      35% 87%,
      25% 87%,
      25% 39%,
      15% 39%,
      15% 88%,
      52% 88%
    );
  }

  30% {
    clip-path: polygon(
      0% 53%,
      93% 53%,
      93% 62%,
      68% 62%,
      68% 37%,
      97% 37%,
      97% 89%,
      13% 89%,
      13% 45%,
      51% 45%,
      51% 88%,
      17% 88%,
      17% 54%,
      81% 54%,
      81% 75%,
      79% 75%,
      79% 76%,
      38% 76%,
      38% 28%,
      61% 28%,
      61% 12%,
      55% 12%,
      55% 62%,
      68% 62%,
      68% 51%,
      0% 51%,
      0% 92%,
      63% 92%,
      63% 4%,
      65% 4%
    );
  }

  45% {
    clip-path: polygon(
      0% 33%,
      2% 33%,
      2% 69%,
      58% 69%,
      58% 94%,
      55% 94%,
      55% 25%,
      33% 25%,
      33% 85%,
      16% 85%,
      16% 19%,
      5% 19%,
      5% 20%,
      79% 20%,
      79% 96%,
      93% 96%,
      93% 50%,
      5% 50%,
      5% 74%,
      55% 74%,
      55% 57%,
      96% 57%,
      96% 59%,
      87% 59%,
      87% 65%,
      82% 65%,
      82% 39%,
      63% 39%,
      63% 92%,
      4% 92%,
      4% 36%,
      24% 36%,
      24% 70%,
      1% 70%,
      1% 43%,
      15% 43%,
      15% 28%,
      23% 28%,
      23% 71%,
      90% 71%,
      90% 86%,
      97% 86%,
      97% 1%,
      60% 1%,
      60% 67%,
      71% 67%,
      71% 91%,
      17% 91%,
      17% 14%,
      39% 14%,
      39% 30%,
      58% 30%,
      58% 11%,
      52% 11%,
      52% 83%,
      68% 83%
    );
  }

  76% {
    clip-path: polygon(
      0% 26%,
      15% 26%,
      77% 75%,
      8% 75%,
      8% 42%,
      4% 42%,
      4% 61%,
      17% 61%,
      17% 12%,
      26% 12%,
      26% 63%,
      73% 63%,
      73% 43%,
      90% 43%,
      90% 67%,
      50% 67%,
      50% 41%,
      50% 84%,
      96% 84%
    );
  }

  90% {
    clip-path: polygon(
      0% 41%,
      13% 41%,
      13% 6%,
      87% 6%,
      87% 93%,
      3% 8%,
      16% 8%,
      16% 79%,
      0% 79%,
      0% 99%,
      92% 99%,
      92% 90%,
      5% 90%,
      5% 60%,
      0% 60%,
      0% 48%,
      89% 48%,
      89% 13%,
      80% 13%,
      80% 43%,
      95% 43%,
      95% 19%,
      80% 19%,
      80% 85%,
      38% 85%,
      38% 62%
    );
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    clip-path: none;
  }
}

.movement {
  /* Normally this position would be absolute & on the layers, set to relative here so we can see it on the div */
  position: relative;
  animation: movement 1s step-end infinite;
}

@keyframes movement {
  0% {
    top: 0px;
    left: -20px;
  }

  15% {
    top: 10px;
    left: 10px;
  }

  60% {
    top: 5px;
    left: -10px;
  }

  75% {
    top: -5px;
    left: 20px;
  }

  100% {
    top: 10px;
    left: 5px;
  }
}

.opacity {
  animation: opacity 1s step-end infinite;
}

@keyframes opacity {
  0% {
    opacity: 0.1;
  }

  5% {
    opacity: 0.7;
  }

  30% {
    opacity: 0.4;
  }

  45% {
    opacity: 0.6;
  }

  76% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.8;
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    opacity: 0;
  }
}

.font {
  animation: font 1s step-end infinite;
}

@keyframes font {
  0% {
    font-weight: 100;
    color: #e0287d;
    filter: blur(3px);
  }

  20% {
    font-weight: 500;
    color: #fff;
    filter: blur(0);
  }

  50% {
    font-weight: 300;
    color: #1bc7fb;
    filter: blur(2px);
  }

  60% {
    font-weight: 700;
    color: #fff;
    filter: blur(0);
  }

  90% {
    font-weight: 500;
    color: #e0287d;
    filter: blur(6px);
  }
}

.glitch span:hover {
  animation: paths 2s step-end infinite;
}

.glitch::before {
  animation: paths 41s step-end infinite, opacity 45s step-end infinite,
    font 48s step-end infinite, movement 40s step-end infinite;
}

.glitch::after {
  animation: paths 41s step-end infinite, opacity 45s step-end infinite,
    font 40s step-end infinite, movement 48s step-end infinite;
}

.hero-container {
  position: relative;
  padding: 10px 15px;
  text-align: center;
}

.environment {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  filter: blur 1px;
}

/* end of code for header glitch ^ */

@media only screen and (max-width: 360px) {
  .chart {
    width: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    font-size: 25px;
    letter-spacing: 3px;
  }
}

@media only screen and (min-width: 600px) {
  .token-address {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  #container {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .margin {
    margin-left: 10%;
  }

  .button-row {
    margin-bottom: 8%;
  }

  .hide {
    display: none;
  }
}
</style>
