<template>
  <a
    class="buy"
    href="https://jup.ag/swap/SOL-GaRph5BcLZZ4sRh6EXKS6V7dicVanhcwa5iWEE6AbEYR"
    target="_BLANK"
  >
    <div class="button-row">
      <p class="buy">Buy on Jupiter Swap</p>
      <img
        class="jupiter-avatar"
        src="../assets/jupiter-logo.svg"
        alt="jupiter swap logo"
      />
    </div>
  </a>
</template>


