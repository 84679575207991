// https://www.chartjs.org/docs/latest/charts/doughnut.html
// Play with these options to make it prettier

export const data = {
  labels: ["Marketing", "Operations", "Development", "General"],
  datasets: [
    {
      label: "Percentage: ",
      backgroundColor: ["#520E7d", "#CCCCFF", "#B0E0E6", "#002366"],
      data: [5, 5, 12, 78],
      hoverOffset: 4,
      rotation: 0,
      weight: 10,
      hoverBorderWidth: 3,
      hoverBorderJoinStyle: "bevel",
    },
  ],
};

// hoverBorderJoinStyle: 'round' | 'bevel' | 'miter'
// hoverBorderColor: Color
// hoverBackgroundColor: Color

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "rgb(255, 255, 255)",
      },
    },
  },
};
