<template>
<div class="chart">
    <Pie :data="data" :options="options" />
</div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'
import * as chartConfig from './tokenomicsConfig.js'

ChartJS.register(ArcElement, Tooltip, Legend);
// configure from this:
// https://www.chartjs.org/docs/latest/configuration/legend.html#legend-title-configuration


export default {
    name: 'TokenomicsChart',
    components: { Pie },
    data() {
        return chartConfig;
    }
}

</script>

<style scoped> 
    .chart {
        margin-bottom: 25px;
    }

</style>