import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import ConstructionView from "@/views/ConstructionView.vue";
import TokenView from "@/views/TokenView.vue";
import SocialView from "@/views/SocialView.vue";
import BurnView from "@/views/BurnView.vue";
import BuyView from "@/views/BuyView.vue";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import PublicProofView from "@/views/PublicProofView.vue";
import WhitePaperView from "@/views/WhitePaperView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/token",
    name: "TokenView",
    component: TokenView,
  },
  {
    path: "/socials",
    name: "SocialView",
    component: SocialView,
  },
  {
    path: "/burns",
    name: "BurnView",
    component: BurnView,
  },
  {
    path: "/buy",
    name: "Buy",
    component: BuyView,
  },
  {
    path: "/whitepaper",
    name: "WhitePaper",
    component: WhitePaperView,
  },
  {
    path: "/construction",
    name: "Construction",
    component: ConstructionView,
  },
  {
    path: "/proof",
    name: "Proof",
    component: PublicProofView,
  },  
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFoundView,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
