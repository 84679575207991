<template>
  <section class="view">
    <section class="container">
      <h1 class="title hover-glitch">TradeBot Burns</h1>
     
      <div class="phrase">
        <img class="avatar" src="../assets/fire-icon.png" alt="fire icon" />
        <h2 class="phrase-text">Feel the Burn</h2>
        <img class="avatar" src="../assets/fire-icon.png" alt="fire icon" />
      </div>
      <div class="stack" style="--stacks: 3">
        <span style="--index: 0"
          ><img
            class="tradebot-size"
            src="../assets/running-bot.png"
            alt="blue robot running"
        /></span>
        <span style="--index: 1"
          ><img
            class="tradebot-size"
            src="../assets/running-bot.png"
            alt="blue robot running"
        /></span>
        <span style="--index: 2"
          ><img
            class="tradebot-size"
            src="../assets/running-bot.png"
            alt="blue robot running"
        /></span>
      </div>

      <div class="button-flex">
        <a
          class="buy"
          href="https://dexscreener.com/solana/7mlmtmvrgg9o7m9qdeezeq4axuurtrsrtbpcwmnlw1y1?maker=HrQBXyKh2mgPoLa8Kka9Vd9hBrCtWgxtAkyvRd8tq7dT"
          target="_blank"
        >
          <div class="button-row">
            <p class="buy">Purchase Transactions</p>
            <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30px"
        fill="#fff"
        fill-rule="evenodd"
        viewBox="0 0 252 300"
        focusable="false"
        class="chakra-icon custom-euf446"
      >
        <path
          d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"
        ></path>
        <path
          d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"
        ></path>
      </svg>
          </div>
        </a>

        <a
          class="buy"
          href="https://solscan.io/account/HrQBXyKh2mgPoLa8Kka9Vd9hBrCtWgxtAkyvRd8tq7dT?activity_type=ACTIVITY_SPL_BURN#transfers"
          target="_blank"
        >
          <div class="button-row">
            <p class="buy">Burn Transactions</p>
            <img class="avatar" src="../assets/fire-icon.png" alt="fire icon" />
          </div>
        </a>        
      </div>
      <p>The TradeBot engine trades against major cryptocurrencies.  Any time profit is made, those profits are sent to wallet address:           <a href="https://solscan.io/account/HrQBXyKh2mgPoLa8Kka9Vd9hBrCtWgxtAkyvRd8tq7dT?activity_type=ACTIVITY_SPL_BURN#transfers"
          target="_blank"
        >{{getDisplaySignature('HrQBXyKh2mgPoLa8Kka9Vd9hBrCtWgxtAkyvRd8tq7dT')}}</a>.</p>
        <p>$TRADEBOT is purchased from JupiterSwap and then subsequently burned.  Evidence for purchases can be seen on <a href="https://dexscreener.com/solana/7mlmtmvrgg9o7m9qdeezeq4axuurtrsrtbpcwmnlw1y1?maker=HrQBXyKh2mgPoLa8Kka9Vd9hBrCtWgxtAkyvRd8tq7dT"
          target="_blank"
        >DexScreener</a> below.  Evidence for burns can be seen on the blockchain using <a href="https://solscan.io/account/HrQBXyKh2mgPoLa8Kka9Vd9hBrCtWgxtAkyvRd8tq7dT?activity_type=ACTIVITY_SPL_BURN#transfers"
          target="_blank">SolScan</a>.</p>
          
          <div id="dexscreener-embed">
            <iframe src="https://dexscreener.com/solana/7mLMtmVrGG9o7m9qDEEZeq4axUUrtrsRtBPCWMNLW1Y1?maker=HrQBXyKh2mgPoLa8Kka9Vd9hBrCtWgxtAkyvRd8tq7dT&embed=1&theme=dark&info=0"></iframe>
          </div>
    </section>
  </section>
</template>
<script>
import burnClient from "../services/BurnClient";

export default {
  name: "BurnView",
  data() {
    return {
      isMobile: false,
      burnList: [],
    };
  },
  created() {
    this.burnList = burnClient.getBurns();
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    getSolLink(signature) {
      return "https://solscan.io/tx/" + signature;
    },
    getDisplaySignature(signature) {
      if (signature && signature.length > 15) {
        return (
          signature.substring(0, 6) +
          "..." +
          signature.substring(signature.length - 6)
        );
      }
      return signature;
    },
    getBurnHeader(header) {
      if (this.isMobile) {
        return "Burned:";
      } else {
        return "$TRADEBOT Burned";
      }
    },

    getSignatureHeader() {
      if (this.isMobile) {
        return "Signature:";
      } else {
        return "Transaction Signature";
      }
    },
    checkScreen() {
      return (this.isMobile = window.innerWidth <= 750);
    },
  },
};
</script>

<style scoped>

#dexscreener-embed {
  position:relative;
  width:100%;
  padding-bottom:200%;
}

@media(min-width:1400px){
  #dexscreener-embed{
    padding-bottom:100%;
  }
}
  
#dexscreener-embed iframe {
  position:absolute;
  width:90%;
  height:90%;
  top:0;
  left:5%;
  border:0;
}


.button-flex {
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 3%;
  margin-bottom: 0px;
}

.button-row:hover {
  border-top: 1px rgb(216, 5, 5) solid;
  border-bottom: 1px rgb(216, 5, 5) solid;
  box-shadow: 3px 2px 10px rgb(216, 5, 5);
}

.signature {
  color: rgb(227, 227, 242);
}

.signature:hover {
  color: rgb(78, 111, 211);
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
}

table {
  width: 90%;
  padding: 10px;
  margin-top: 3%;
  margin-bottom: 3%;
}

th {
  padding-top: 5px;
  padding-bottom: 10px;
  text-shadow: 2px 2px 5px black;
}

th,
td {
  padding: 5%;
}

.phrase {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
  color: rgb(216, 5, 5);
}

.phrase-text {
  margin: 0px;
  margin-top: 5px;
  text-shadow: 2px 2px 5px black;
}

@media only screen and (max-width: 768px) {
  table {
    display: none;
  }

  .card {
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .box {
    --b: 2px;
    --c: #888888;
    --w: 25px;

    border: var(--b) solid #0000;
    --_g: #0000 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background: conic-gradient(
          from 90deg at top var(--b) left var(--b),
          var(--_g)
        )
        0 0 / var(--_p),
      conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100%
        0 / var(--_p),
      conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0
        100% / var(--_p),
      conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
        100% 100% / var(--_p);

    width: 100%;
    max-width: 200px;
    height: 250px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .card-text {
    margin: 0px;
    color: white;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }
}

@media only screen and (min-width: 768px) {
  th,
  td {
    padding: 2%;
  }

  .card {
    display: none;
  }

  .button-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;
  }
}
</style>
