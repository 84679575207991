<template>
  <a href="https://twitter.com/tradebotcash" class="card-frame">
    <div class="socials-box">
      <svg viewBox="0 0 24 24" aria-label="X" role="img" class="logo">
        <g>
          <path
            d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
          ></path>
        </g>
      </svg>
      <p>
        Get the latest updates and news by following TradeBot on X.
      </p>
      <a class="socials-btn shake" href="https://twitter.com/tradebotcash" target="_BLANK"
        >Join Now</a
      >
    </div>
  </a>
</template>

<style scoped>
.logo {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  color-scheme: dark;
  scrollbar-color: rgb(62, 65, 68) rgb(22, 24, 28);
  pointer-events: auto;
  list-style: none;
  display: inline-block;
  flex-grow: 1;
  color: rgba(231, 233, 234, 1);
  vertical-align: text-bottom;
  position: relative;
  max-width: 100%;
  user-select: none;
  fill: currentcolor;
  height: 1.75rem;
}

.socials-box:hover {
  background-color: rgb(227, 227, 242, 0.2);
  --c: rgb(227, 227, 242);
}

.socials-box:hover > .socials-btn {
  --c: rgb(227, 227, 242);
}

p {
  margin-top: 17px;
}

</style>
