<template>
    <footer id="app-footer">
      <div class="solana-row">
      <img class="sizing" src="../assets/solana.png"> 
      <div class="solana">Powered by Solana</div>
    </div> 
    </footer>
</template>

<style scoped>
#app-footer {
  border-top: 1.5px ridge black;
  background-color: rgba(18, 18, 18, 0.90);
  height: 35px;
  display: flex;
  align-content: center;
  display: none;
}

.solana {
  font-size: 16px;
  text-shadow: 2px 2px 5px black;
}

.solana:hover {
  filter: brightness(85%);
  transition: ease-in-out .3s;
}

.solana-row {
  color: #99999a;
  text-decoration: none;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-left: 1%;
}

.sizing {
  width: 20px;
  border-radius: 6px;
}

@media only screen and (min-width: 768px) {
#app-footer {
  display: block;
}

}
</style>